/* Fonts */
@font-face {
  font-family: 'Bevellier';
  src: local('Bevellier'), url('./assets/Bevellier-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Bevellier';
  src: local('Bevellier'), url('./assets/Bevellier-Bold.ttf') format('truetype');
  font-weight: 700;
}
